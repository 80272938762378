<template>
  <div>
    <profile-list-add-new
      :is-add-new-profile-sidebar-active.sync="isAddNewProfileSidebarActive"
      @refetch-data="refetchData"
    />

    <!-- Filters -->
    <profile-list-filters
      :include-enrolled-filter.sync="includeEnrolledFilter"
      :status-filter.sync="statusFilter"
      :status-options="statusOptions"
      :create-at-start-filter.sync="createAtStartFilter"
      :create-at-end-filter.sync="createAtEndFilter"
      :pathway-filter.sync="pathwayFilter"
      :funding-filter.sync="fundingFilter"
      :funding-options="fundingSourceOptions"
      :resume-filter.sync="resumeFilter"
      :resume-options="uploadedResumeOptions"
      :coe-filter.sync="coeFilter"
      :coe-options="uploadedCoeOptions"
      :submission-filter.sync="submissionFilter"
      :submission-options="submittedToVaOptions"
      :foresight-filter.sync="foresightFilter"
      :foresight-options="foresightOptions"
    />

    <!-- Table Container Card -->
    <b-card no-body class="mb-0">
      <div class="m-2 mb-0">
        <b-row>
          <!-- Per Page -->
          <b-col cols="12" md="6" class="d-flex align-items-center justify-content-start mb-1 mb-md-0">
            <label>Show</label>
            <v-select
              v-model="perPage"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="perPageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block mx-50"
            />
            <label>entries</label>

            <b-button :to="{ name: 'apps-roster-search-profile' }" class="ml-1" variant="primary">
              Advanced Search
            </b-button>
          </b-col>

          <!-- Search -->
          <b-col cols="12" md="6">
            <div class="d-flex align-items-center justify-content-end">
              <b-form-input v-model="searchQuery" class="d-inline-block mr-1" placeholder="Search..." />
              <b-button variant="primary" @click="isAddNewProfileSidebarActive = true">
                <span class="text-nowrap">Create Profile</span>
              </b-button>
            </div>
          </b-col>
        </b-row>
      </div>

      <b-table
        ref="refProfileListTable"
        class="position-relative"
        :items.sync="fetchProfiles"
        responsive
        :fields="tableColumns"
        :filter.sync="searchQuery"
        :filterByFormatted="true"
        primary-key="id"
        :sort-by.sync="sortBy"
        show-empty
        empty-text="No matching records found"
        :sort-desc.sync="isSortDirDesc"
        :current-page.sync="currentPage"
        :per-page.sync="perPage"
      >
        <!-- Column: Session -->
        <template #cell(studentId)="data">
          {{ data.item.student.studentId }}
        </template>

        <!-- Column: Session -->
        <template #cell(name)="data">
          <b-link
            :to="{ name: 'apps-students-view', params: { id: data.item.student.id } }"
            class="font-weight-bold d-block text-nowrap"
          >
            {{ data.item.student.fullName }}
          </b-link>
          <small class="text-muted">#{{ data.item.student.studentId }}</small>
        </template>
        <!-- Column: Session -->
        <template #cell(rep)="data">
          <div class="text-nowrap" v-if="data.item.representative">
            <b-media vertical-align="center">
              <template #aside>
                <b-avatar
                  size="32"
                  :src="data.item.representative.avatar"
                  :text="avatarText(data.item.representative.name)"
                  :variant="`light-${resolveUserRoleVariant(data.item.representative.role)}`"
                  :to="{ name: 'apps-users-view', params: { id: data.item.representative.id } }"
                />
              </template>
              <b-link
                :to="{ name: 'apps-users-view', params: { id: data.item.representative.id } }"
                class="font-weight-bold d-block text-nowrap"
              >
                {{ data.item.representative.name }}
              </b-link>
              <small class="text-muted">@{{ data.item.representative.username }}</small>
            </b-media>
          </div>
          <div class="text-nowrap" v-else>System</div>
        </template>

        <!-- Column: Session -->
        <template #cell(pathway)="data">
          <div class="text-nowrap" v-if="data.item.pathway">
            <b-media vertical-align="center">
              <template #aside>
                <b-avatar
                  size="32"
                  :src="data.item.pathway.avatar"
                  :text="avatarText(data.item.pathway.name)"
                  :variant="`light-${resolvePathwayStatusVariant(data.item.pathway.status)}`"
                  :to="{ name: 'apps-roster-pathway-view', params: { id: data.item.pathway.id } }"
                />
              </template>
              <b-link
                :to="{ name: 'apps-roster-pathway-view', params: { id: data.item.pathway.id } }"
                class="font-weight-bold d-block text-nowrap"
              >
                {{ data.item.pathway.name }}
              </b-link>
              <small v-if="data.item.pathwayDate" class="text-muted">
                {{ moment(data.item.pathwayDate).utc().format('MMM YYYY') }}
              </small>
              <small v-else class="text-muted"> No Preference </small>
            </b-media>
          </div>
          <div v-else-if="data.item.subject">
            <b-media vertical-align="center">
              <template #aside>
                <b-avatar
                  size="32"
                  :src="data.item.subject.avatar"
                  :text="avatarText(data.item.subject.name)"
                  :variant="`light-${resolveSubjectStatusVariant(data.item.subject.status)}`"
                  :to="{ name: 'apps-roster-subject-view', params: { id: data.item.subject.id } }"
                />
              </template>
              <b-link
                :to="{ name: 'apps-roster-subject-view', params: { id: data.item.subject.id } }"
                class="font-weight-bold d-block"
                style="inline-size: 300px"
              >
                {{ data.item.subject.name }}
              </b-link>
              <small v-if="data.item.pathwayDate" class="text-muted">
                {{ moment(data.item.pathwayDate).utc().format('MMM YYYY') }}
              </small>
              <small v-else class="text-muted"> No Preference </small>
            </b-media>
          </div>
          <div class="text-nowrap" v-else>
            {{ data.item.metadata ? data.item.metadata.certificate || 'Not specified' : 'Not specified' }}
          </div>
        </template>
        <!-- Column: Session -->
        <template #cell(subject)="data">
          <div class="text-nowrap" v-if="data.item.subject">
            <b-media vertical-align="center">
              <template #aside>
                <b-avatar
                  size="32"
                  :src="data.item.subject.avatar"
                  :text="avatarText(data.item.subject.name)"
                  :variant="`light-${resolvePathwayStatusVariant(data.item.subject.status)}`"
                  :to="{ name: 'apps-roster-subject-view', params: { id: data.item.subject.id } }"
                />
              </template>
              <b-link
                :to="{ name: 'apps-roster-subject-view', params: { id: data.item.subject.id } }"
                class="font-weight-bold d-block text-nowrap"
              >
                {{ data.item.subject.name }}
              </b-link>
              <small v-if="data.item.pathwayDate" class="text-muted">
                {{ moment(data.item.pathwayDate).utc().format('MMM YYYY') }}
              </small>
              <small v-else class="text-muted"> No Preference </small>
            </b-media>
          </div>
          <div class="text-nowrap" v-else>
            {{ data.item.metadata ? data.item.metadata.certificate || 'Not specified' : 'Not specified' }}
          </div>
        </template>

        <!-- Column: Session -->
        <template #cell(session)="data">
          <div class="text-nowrap" v-if="data.item.session">
            <b-link
              :to="{ name: 'apps-roster-session-view', params: { id: data.item.session.id } }"
              class="font-weight-bold d-block text-nowrap"
            >
              {{ data.item.session.name }}
            </b-link>
            <small class="text-muted">{{ title(data.item.session.status) }}</small>
          </div>
          <div class="text-nowrap" v-else>
            <feather-icon
              :id="`profile-row-${data.item.id}-set-session-icon`"
              icon="PlusCircleIcon"
              class="cursor-pointer"
              size="16"
              @click="startSessionSelect(data.item)"
            />

            <b-tooltip
              title="Assign Session"
              class="cursor-pointer"
              :target="`profile-row-${data.item.id}-set-session-icon`"
            />

            Not Enrolled
          </div>
        </template>

        <!-- Column: Session -->
        <template #cell(email)="data">
          <span class="text-nowrap">{{ data.item.student.email }}</span>
        </template>

        <!-- Column: Attributes - Program -->
        <template #cell(fundingSource)="data">
          <b-badge
            pill
            :variant="`light-${resolveProfileProgramVariant(data.item.attributes.fundingSource)}`"
            class="text-nowrap text-capitalize"
          >
            {{ title(data.item.attributes.fundingSource) }}
          </b-badge>
        </template>

        <!-- Column: Attributes - Payment Status -->
        <template #cell(paymentStatus)="data">
          <b-badge
            pill
            :variant="`light-${resolveProfilePaymentStatusVariant(data.item.attributes.paymentStatus)}`"
            class="text-nowrap text-capitalize"
          >
            {{ title(data.item.attributes.paymentStatus) }}
          </b-badge>
        </template>

        <!-- Column: Attributes - Uploaded Resume -->
        <template #cell(uploadedResume)="data">
          <div class="text-nowrap">
            <feather-icon
              :id="`profile-row-${data.item.id}-upload-resume-icon`"
              icon="UploadIcon"
              class="cursor-pointer"
              size="16"
              @click="startUpload(data.item, 'enrollment', 'resume')"
            />

            <b-tooltip
              title="Upload Resume"
              class="cursor-pointer"
              :target="`profile-row-${data.item.id}-upload-resume-icon`"
            />
            &nbsp;
            <b-badge
              pill
              :variant="`light-${resolveProfileYNVariant(data.item.attributes.uploadedResume)}`"
              class="text-capitalize"
            >
              {{ title(data.item.attributes.uploadedResume) }}
            </b-badge>
          </div>
        </template>

        <!-- Column: Attributes - Uploaded COE -->
        <template #cell(uploadedCoe)="data">
          <div class="text-nowrap">
            <feather-icon
              :id="`profile-row-${data.item.id}-upload-coe-icon`"
              icon="UploadIcon"
              class="cursor-pointer"
              size="16"
              @click="startUpload(data.item, 'enrollment', 'coe')"
            />

            <b-tooltip title="Upload COE" class="cursor-pointer" :target="`profile-row-${data.item.id}-upload-coe-icon`" />
            &nbsp;
            <b-badge
              pill
              :variant="`light-${resolveProfileYNVariant(data.item.attributes.uploadedCoe)}`"
              class="text-capitalize"
            >
              {{ title(data.item.attributes.uploadedCoe) }}
            </b-badge>
          </div>
        </template>

        <!-- Column: Attributes - Uploaded Transcript -->
        <template #cell(uploadedTranscript)="data">
          <div class="text-nowrap">
            <feather-icon
              :id="`profile-row-${data.item.id}-upload-transcript-icon`"
              icon="UploadIcon"
              class="cursor-pointer"
              size="16"
              @click="startUpload(data.item, 'transcript', 'prior')"
            />

            <b-tooltip title="Upload Transcript" class="cursor-pointer" :target="`profile-row-${data.item.id}-upload-transcript-icon`" />
            &nbsp;
            <b-badge
              pill
              :variant="`light-${resolveProfileYNVariant(data.item.attributes.uploadedTranscript)}`"
              class="text-capitalize"
            >
              {{ title(data.item.attributes.uploadedTranscript) }}
            </b-badge>
          </div>
        </template>

        <!-- Column: Attributes - VA Submission -->
        <template #cell(submittedToVa)="data">
          <feather-icon
            :id="`profile-row-${data.item.id}-update-submission`"
            icon="CheckCircleIcon"
            class="cursor-pointer"
            size="16"
            @click="markProfileAsSubmitted(data.item)"
            v-if="data.item.attributes.submittedToVa === 'no'"
          />

          <b-tooltip
            title="Mark as Submitted"
            class="cursor-pointer"
            :target="`profile-row-${data.item.id}-update-submission`"
            v-if="data.item.attributes.submittedToVa === 'no'"
          />

          <b-badge
            pill
            :variant="`light-${resolveProfileYNVariant(data.item.attributes.submittedToVa)}`"
            class="text-capitalize"
          >
            {{ title(data.item.attributes.submittedToVa) }}
          </b-badge>
        </template>

        <!-- Column: Session -->
        <template #cell(createdAt)="data">
          {{ moment(data.item.createdAt).format('L') }}
        </template>

        <!-- Column: Status -->
        <template #cell(status)="data">
          <b-badge pill :variant="`light-${resolveProfileStatusVariant(data.item.status)}`" class="text-capitalize">
            {{ title(data.item.status) }}
          </b-badge>
        </template>

        <!-- Column: Actions -->
        <template #cell(actions)="data">
          <div class="text-nowrap">
            <b-link :to="{ name: 'apps-roster-profile-edit', params: { id: data.item.id } }">
              <feather-icon :id="`profile-row-${data.item.id}-edit-icon`" icon="EditIcon" class="cursor-pointer" size="16" />
              <b-tooltip title="Edit Profile" class="cursor-pointer" :target="`profile-row-${data.item.id}-edit-icon`" />
            </b-link>

            <b-dropdown variant="link" no-caret :right="$store.state.appConfig.isRTL">
              <template #button-content>
                <feather-icon icon="MoreVerticalIcon" size="16" class="align-middle text-body" />
              </template>

              <b-dropdown-item :to="{ name: 'apps-roster-profile-edit', params: { id: data.item.id } }">
                <feather-icon icon="FileTextIcon" />
                <span class="align-middle ml-50">Edit</span>
              </b-dropdown-item>
              <b-dropdown-item @click="archiveProfile(data.item.id)">
                <feather-icon icon="ArchiveIcon" />
                <span class="align-middle ml-50">Archive</span>
              </b-dropdown-item>
            </b-dropdown>
          </div>
        </template>
      </b-table>
      <div class="mx-2 mb-2">
        <b-row>
          <b-col cols="12" sm="6" class="d-flex align-items-center justify-content-center justify-content-sm-start">
            <span class="text-muted">Showing {{ dataMeta.from }} to {{ dataMeta.to }} of {{ dataMeta.of }} entries</span>
          </b-col>
          <!-- Pagination -->
          <b-col cols="12" sm="6" class="d-flex align-items-center justify-content-center justify-content-sm-end">
            <b-pagination
              v-model="currentPage"
              :total-rows="totalProfiles"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon icon="ChevronLeftIcon" size="18" />
              </template>
              <template #next-text>
                <feather-icon icon="ChevronRightIcon" size="18" />
              </template>
            </b-pagination>
          </b-col>
        </b-row>
      </div>
    </b-card>

    <profile-add-to-session
      :is-add-session-open.sync="isAddSessionOpen"
      v-on:refresh="refetchData"
      :profile="sessionProfile"
    />
    <profile-upload-document
      :is-upload-document-open.sync="upload.isUploadDocumentOpen"
      :profile="upload.profile"
      :document-category="upload.documentCategory"
      :document-type="upload.documentType"
      v-on:refresh="refetchData"
    />
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BFormInput,
  BButton,
  BTable,
  BMedia,
  BAvatar,
  BLink,
  BBadge,
  BDropdown,
  BDropdownItem,
  BPagination,
  BTooltip,
} from 'bootstrap-vue';
import vSelect from 'vue-select';
import store from '@/store';
import { ref, onUnmounted } from '@vue/composition-api';
import { avatarText, title } from '@core/utils/filter';
import ProfileListFilters from './ProfileListFilters.vue';
import useUsersList from '../../user/users-list/useUsersList';
import useProfilesList from './useProfilesList';
import usePathwaysList from '../pathways-list/usePathwaysList';
import useSubjectsList from '../subjects-list/useSubjectsList';
import rosterStoreModule from '../rosterStoreModule';
import ProfileListAddNew from './ProfileListAddNew.vue';
import moment from 'moment';
import ProfileUploadDocument from '@/views/apps/roster/profile-list/components/ProfileUploadDocument';
import ProfileAddToSession from '@/views/apps/roster/profile-list/components/ProfileAddToSession';

export default {
  components: {
    ProfileAddToSession,
    ProfileUploadDocument,
    ProfileListFilters,
    ProfileListAddNew,

    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    BTooltip,

    vSelect,
  },
  data: () => {
    return {
      isAddSessionOpen: false,
      sessionProfile: undefined,

      upload: {
        isUploadDocumentOpen: false,

        profile: undefined,
        documentType: undefined,
        documentCategory: undefined,
      },
    };
  },
  methods: {
    startSessionSelect(profile) {
      this.sessionProfile = profile;
      this.isAddSessionOpen = true;
    },
    startUpload(profile, documentCategory, documentType) {
      this.upload = {
        isUploadDocumentOpen: true,
        profile,
        documentCategory,
        documentType,
      };
    },
    markProfileAsSubmitted(profile) {
      store
        .dispatch('app-roster/updateProfile', {
          id: profile.id,
          profileData: {
            attributes: { ...profile.attributes, submittedToVa: 'yes' },
          },
        })
        .then((response) => {
          this.refetchData();
        });
    },
    archiveProfile(profileId) {
      store
        .dispatch('app-roster/updateProfile', {
          id: profileId,
          profileData: {
            status: 'archived',
          },
        })
        .then((response) => {
          this.refetchData();
        });
    },
  },
  mounted() {
    if (this.$route.query.op === 'create') {
      this.isAddNewProfileSidebarActive = true;
    }
  },
  setup() {
    const PROFILE_APP_STORE_MODULE_NAME = 'app-roster';

    // Register module
    if (!store.hasModule(PROFILE_APP_STORE_MODULE_NAME))
      store.registerModule(PROFILE_APP_STORE_MODULE_NAME, rosterStoreModule);

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(PROFILE_APP_STORE_MODULE_NAME)) store.unregisterModule(PROFILE_APP_STORE_MODULE_NAME);
    });

    const isAddNewProfileSidebarActive = ref(false);

    const {
      fetchProfiles,
      tableColumns,
      perPage,
      currentPage,
      totalProfiles,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refProfileListTable,
      refetchData,

      foresightOptions,
      statusOptions,
      fundingSourceOptions,

      submittedToVaOptions,
      uploadedCoeOptions,
      uploadedResumeOptions,
      uploadedTranscriptOptions,

      // UI
      resolveProfileRoleVariant,
      resolveProfileRoleIcon,
      resolveProfileVisibilityVariant,
      resolveProfileStatusVariant,

      resolveProfilePaymentStatusVariant,
      resolveProfileProgramVariant,
      resolveProfileYNVariant,
      // Extra Filters
      visibilityFilter,
      statusFilter,
      foresightFilter,
      includeEnrolledFilter,
      pathwayFilter,
      fundingFilter,

      resumeFilter,
      coeFilter,
      submissionFilter,

      createAtStartFilter,
      createAtEndFilter,
    } = useProfilesList();

    const { resolveUserRoleVariant } = useUsersList();
    const { resolvePathwayStatusVariant } = usePathwaysList();
    const { resolveSubjectStatusVariant } = useSubjectsList();

    return {
      // Sidebar
      isAddNewProfileSidebarActive,

      fetchProfiles,
      tableColumns,
      perPage,
      currentPage,
      totalProfiles,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refProfileListTable,
      refetchData,

      // Filter
      avatarText,
      title,

      resolveUserRoleVariant,

      // UI
      resolveProfileRoleVariant,
      resolveProfileRoleIcon,
      resolveProfileVisibilityVariant,
      resolveProfileStatusVariant,

      resolveProfilePaymentStatusVariant,
      resolveProfileProgramVariant,
      resolveProfileYNVariant,

      resolvePathwayStatusVariant,
      resolveSubjectStatusVariant,

      foresightOptions,
      statusOptions,
      fundingSourceOptions,

      submittedToVaOptions,
      uploadedCoeOptions,
      uploadedResumeOptions,
      uploadedTranscriptOptions,

      // Extra Filters
      visibilityFilter,
      statusFilter,
      foresightFilter,
      includeEnrolledFilter,
      pathwayFilter,
      fundingFilter,

      resumeFilter,
      coeFilter,
      submissionFilter,

      createAtStartFilter,
      createAtEndFilter,

      //
      moment,
    };
  },
  metaInfo() {
    return {
      title: 'Profiles',
    };
  },
};
</script>

<style lang="scss">
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
