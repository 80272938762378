<template>
  <div>
    <b-modal
    id="modal-lg"
    ok-title="Add to Session"
    centered
    size="lg"
    title="Search for sessions"
    ref="modal"
    @hidden="() => $emit('update:is-add-session-open', false)"
    :visible="isAddSessionOpen"
    :hide-footer="true"
  >
    <b-card no-body>
      <b-row>
        <b-col cols="12" lg="6">
          <b-pagination
            v-model="currentPage"
            :total-rows="totalResults"
            :per-page="perPage"
            aria-controls="table-test"
          ></b-pagination>
        </b-col>

        <b-col cols="12" md="6">
          <b-form-group class="justify-content-end">
            <div class="d-flex align-items-center">
              <b-form-checkbox id="restricted-search" v-model="restricted" name="restricted-search" class="mr-3">
                Restricted
              </b-form-checkbox>

              <label class="mr-1">Search</label>
              <b-form-input
                v-model="searchTerm"
                placeholder="Search"
                type="text"
                class="d-inline-block"
                @input="handleSearch"
              />
            </div>
          </b-form-group>
        </b-col>
      </b-row>

      <b-table
        id="table-test"
        ref="refSessionTable"
        :busy.sync="isBusy"
        :items="sessionProvider"
        :fields="sessionFields"
        :per-page="perPage"
        :current-page="currentPage"
        :filter="searchTerm"
        :sort-by.sync="sortBy"
        :sort-desc.sync="sortDesc"
        :responsive="true"
      >
        <template #cell(status)="data">
          {{ title(data.item.status) }}
        </template>

        <template #cell(startDate)="data">
          {{ data.item.startDate ? moment(data.item.startDate).utc().format('l') : 'n/a' }}
        </template>

        <template #cell(actions)="data">
          <b-button @click="assignSession(data.item)" variant="primary" size="sm">Select</b-button>
        </template>
      </b-table>
    </b-card>
  </b-modal>



    <!-- <b-modal
      id="modal-lg"
      ok-title="Add to Session"
      centered
      size="lg"
      title="Input enrollment info"
      ref="modalStage2"
      @hidden="() => isAddProfileMetadataOpen = false"
      :visible="isAddProfileMetadataOpen"
      :hide-footer="false"
    >
      <b-card no-body>
        <b-row>

          <b-col cols="12" md="12">
            <b-form-group class="justify-content-end">
              <div class="d-flex align-items-center">
                <b-form-checkbox id="restricted-search" v-model="metadata.restricted" name="restricted-search" class="mr-3">
                  Restricted
                </b-form-checkbox>

                <label class="mr-1">Search</label>
                <b-form-input
                  v-model="searchTerm"
                  placeholder="Search"
                  type="text"
                  class="d-inline-block"
                  @input="handleSearch"
                />
              </div>
            </b-form-group>
          </b-col>

          <b-col cols='12'>
            <pre>
              {{ JSON.stringify(metadata, null, 2) }}
            </pre>
          </b-col>




          
        </b-row>
      </b-card>
    </b-modal> -->


    <b-modal
      id="modal-lg"
      ok-title="Add to Session"
      centered
      size="lg"
      title="Additional enrollment info"
      ref="modalStage2"
      @hidden="() => isAddProfileMetadataOpen = false"
      :visible="isAddProfileMetadataOpen"
      :hide-footer="false"
      @ok="assignMetadata"
    >
      <b-card no-body class="p-1">
        <b-row class="mb-1">
          <b-col cols="12">
            <label id="toc-label">Transfer of Credit</label>
            <b-form-checkbox id="template-data-default" v-model="metadata.toc.enabled" switch />

            <b-tooltip title="Transfer of Credit" class="cursor-pointer" target="toc-label" />
          </b-col>
        </b-row>

        <b-row class="mb-1" v-if="metadata.toc.enabled">

          <b-col cols="12" lg="4">
            <label for="toc-amount">Amount</label>
            <b-input-group prepend="$">
              <cleave
                id="toc-amount"
                v-model="metadata.toc.amount"
                class="form-control"
                :raw="true"
                :options="options.number"
                placeholder="0"
              />
            </b-input-group>
          </b-col>

          <b-col cols="12" lg="4">
            <label for="toc-date-start">Start Date</label>
            <b-form-datepicker class="w-100" id="toc-date-start" :value="metadata.toc.startDate"
              @input="(val) => (metadata.toc.startDate = val)"
              :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }" locale="en" />
          </b-col>

          <b-col cols="12" lg="4">
            <label for="toc-date-end">End Date</label>
            <b-form-datepicker class="w-100" id="toc-date-end" :value="metadata.toc.endDate"
              @input="(val) => (metadata.toc.endDate = val)"
              :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }" locale="en" />
          </b-col>

          <hr/>
        </b-row>

        <!-- <b-row>
          <b-col>

            <b-form-group label='Reassignment Reason' label-for='transfer-reason'>
              <b-form-input id='transfer-reason' v-model='transferReason' autofocus
                :state='!!transferReason && transferReason.length > 0' trim placeholder='' />
            </b-form-group>

          </b-col>
        </b-row> -->
      </b-card>
    </b-modal>
  </div>
</template>

<script>
import {
  BAvatar,
  BTable,
  BBadge,
  BPagination,
  BInputGroup,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BDropdownItem,
  BDropdown,
  BFormCheckbox,
  BFormDatepicker,
  BCard,
  BCol,
  BRow,
  BButton,
  BTooltip,
} from 'bootstrap-vue';

import Cleave from 'vue-cleave-component';
import { VueGoodTable } from 'vue-good-table';
import 'prismjs';
import 'prismjs/themes/prism-tomorrow.css';
import Prism from 'vue-prism-component';
import store from '@/store/index';
import { onUnmounted, ref } from '@vue/composition-api';
import rosterStoreModule from '@/views/apps/roster/rosterStoreModule';
import router from '@/router';
import ToastificationContent from '@core/components/toastification/ToastificationContent';
import { useToast } from 'vue-toastification/composition';
import studentStoreModule from '@/views/apps/student/studentStoreModule';
import moment from 'moment';
import { title } from '@core/utils/filter';

export default {
  components: {
    Cleave,
    VueGoodTable,
    BAvatar,
    BCol,
    BRow,
    BButton,
    BTable,
    BBadge,
    BPagination,
    BInputGroup,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BFormCheckbox,
    BFormDatepicker,
    Prism,
    BDropdownItem,
    BDropdown,
    BCard,
    BTooltip,
  },
  data() {
    return {
      isBusy: false,
      searchTerm: '',
      perPage: 10,
      currentPage: 0,
      totalResults: 0,

      sessionFields: [
        { key: 'name', sortable: true },
        { key: 'startDate', sortable: true, label: 'Start' },
        { key: 'status', sortable: true },
        { key: 'actions' },
      ],

      restricted: true,

      sortBy: 'startDate',
      sortDesc: true,

      session: undefined,
      metadata: { toc: { enabled: false } },


      options: {
        number: {
          numeral: true,
          numeralThousandsGroupStyle: 'thousand',
        },
      },

      isAddProfileMetadataOpen: false,
    };
  },
  props: {
    isAddSessionOpen: {
      type: Boolean,
    },
    profile: {
      type: Object,
    },
  },
  watch: {
    restricted(val) {
      this.$refs.refSessionTable.refresh();
    },
  },
  methods: {
    sessionProvider(ctx) {
      let adl = {};

      console.log(`prof`, this.profile);

      if (this.restricted && this.profile.pathway) {
        adl.pathway = this.profile.pathway.id;
      }

      const promise = store.dispatch('app-roster/fetchSessions', {
        search: ctx.filter,
        limit: ctx.perPage,
        page: ctx.currentPage,
        sortBy: `${ctx.sortBy || 'startDate'}:${ctx.sortDesc ? 'desc' : 'asc'}`,

        // populate: 'student',
        ...adl,
      });

      return promise
        .then((response) => {
          const { results, totalResults } = response.data;
          this.rows = results;
          this.totalResults = totalResults;
          return results;
        })
        .catch(() => {
          this.toast({
            component: ToastificationContent,
            props: {
              title: 'Error fetching session list',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          });

          return [];
        });
    },
    submitAssign() {

      store
        .dispatch('app-roster/addStudentsToSession', {
          sessionId: this.session.id,
          profileIds: [this.profile.id],
          metadata: this.metadata,
        })
        .then((response) => {
          const { data: session } = response;

          if (!session.profiles.includes(this.profile.id)) {
            return this.$toast({
              component: ToastificationContent,
              props: {
                icon: 'XIcon',
                variant: 'error',

                title: 'Failed to add to session',
                text: 'This student is already assigned to this session',
              },
            });
          }

          this.$emit('update:is-add-session-open', false);
          this.$emit('refresh', true);

          return this.$toast({
            component: ToastificationContent,
            props: {
              icon: 'EditIcon',
              variant: 'success',

              title: 'Added profile to session',
              text: 'This student has been assigned to this session',
            },
          });
        })
        .catch((err) => {
          console.error(`failed to add profile to session`, err);
        });
    },
    assignMetadata() {
      console.log('> assigning metadata', this.metadata)
      this.isAddProfileMetadataOpen = false;
      // this.$emit('update:is-add-profile-metadata-open', false)

      this.submitAssign()
    },
    assignSession(session) {
      this.session = session;
      this.isAddProfileMetadataOpen = true;
      this.$emit('update:is-add-session-open', false);
    },
    selectProfile(profile) {
      this.profile = profile;
      this.stage = 'session';
    },
    handleSearch(searching) {
      this.searchTerm = searching;
    },
  },

  setup() {
    const ROSTER_APP_STORE_MODULE_NAME = 'app-roster';

    // Register module
    if (!store.hasModule(ROSTER_APP_STORE_MODULE_NAME))
      store.registerModule(ROSTER_APP_STORE_MODULE_NAME, rosterStoreModule);

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(ROSTER_APP_STORE_MODULE_NAME)) store.unregisterModule(ROSTER_APP_STORE_MODULE_NAME);
    });

    const toast = useToast();

    return { toast, title, moment };
  },
};
</script>
